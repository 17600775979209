import Vue from 'vue'
import VueRouter from 'vue-router'
import { scrollTo } from '@/utils/scroll-to'
// import Home from '../views/Home.vue'

const Layout = () => import('../views/layout.vue')
const Home = () => import('../views/Home/home.vue')
const List = ()=>import('../views/List/list.vue')
const Login = ()=>import('../views/Login/login.vue')
const User = ()=>import('../views/User/user.vue')
const Detail = ()=>import('../views/Detail/detail.vue')
const Read = ()=>import('../views/Read/read.vue')
const Phone = ()=>import('../views/Phone/phone.vue')
const Video = ()=>import('../views/Video/video.vue')
const VideoDetail=()=>import('../views/Video/detail.vue')
const hall=()=>import('../views/Hall/hall.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    name: 'index',
    redirect: '/home',
    children: [
      {path: 'home',name:'home', component: Home},
      {path: 'list',name:'list', component: List},
      {path: 'user',name:'user', component: User},
      {path: 'video',name:'video', component: Video},
      {path: 'hall',name:'hall', component: hall},
      {path: 'detail/:id', component: Detail},
      {path: 'video/:id',name:'videoDetail', component: VideoDetail},
    ]
  },
  {path: '/read',name:'read', component: Read},
  {path: '/phone',name:'phone', component: Phone},
  {path: '/login', name: 'login', component: Login},
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    scrollTo(0, 800)
  }
})

export default router
